import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";

import NorthstarLogin from "../../components/Login";
import { useAuthContext } from "../../contexts/authContext";
import useURLQueryParameter from "../../hooks/queryParamHook";
import Header from "../../components/Header";
import LoadingStatus from "../../components/LoadingStatus";

const LoginPage: React.FC = () => {
  const { whoami: me, isAuthenticating } = useAuthContext();
  const [redirectTo] = useURLQueryParameter("redirect_to");

  useEffect(() => {
    if (me?.username) {
      navigate(redirectTo || "/");
    }
  }, [me?.username]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BAM Applied Insights | Login Page</title>
      </Helmet>

      <div className="flex flex-col min-h-screen scroll-smooth font-roboto">
        <Header showNav={false} />
        <main className="flex flex-col flex-1 w-full overflow-hidden bg-bam-light-blue/50">
          {isAuthenticating && <LoadingStatus loadingType="login_loading" />}
          {!me && !isAuthenticating && <NorthstarLogin />}
        </main>
      </div>
    </>
  );
};

export default LoginPage;
